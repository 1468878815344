import React, { Component } from 'react';
import logo from '../assets/images/LOGO.png';
import logo_icon from '../assets/images/Vector-Smart-Object.png';
import favicon_icon from '../assets/images/favicon-icon.png';
import $ from 'jquery';
import { Link, Redirect } from 'react-router-dom';
import Axios from "axios";
import { api_url } from '../Config';
import swal from 'sweetalert';
class Register extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            redirectToReferrer: false,
            phone: "",
            name: "",
            shipping_address: "",
            country_codee: "92",
            repassword: "",
            password: "",
            loadingBtn: false,
            loginToHome: false,
            building: '',
            buildingErr: '',
            street: '',
            streetErr: '',
            area: '',
            geo_location_address: '',
            floor: '',
            floorErr: '',
            noterider: ' ',
            buildingValue: '',
            streetValue: '',
            flootValue: '',
            sectorValue: '',
            lat: '',
            long: '',
        };
        this.getLogin = this.getLogin.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getLocation = this.getLocation.bind(this);
    }
    componentDidMount() {
        this.getLocation();
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");
        // });3400
        // $(window).load(function() {
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");


    }
    getLocation() {
        // swal('click working')
        function latlong(lat, long) {
            document.getElementById("latitude").setAttribute("value", lat);
            document.getElementById("longitude").setAttribute("value", long);
        }

        const google = window.google
        var componentForm = {
            // street_number: 'short_name',
            // route: 'long_name',
            // sublocality_level_1: 'long_name',
        };

        (function () {
            navigator.geolocation.getCurrentPosition(function (position) {
                getUserAddressBy(position.coords.latitude, position.coords.longitude);
                latlong(position.coords.latitude, position.coords.longitude)
            },
                function (error) {
                    console.log("The Locator was denied :(")
                })
            var locatorSection = document.getElementById("location-input-section")

            function init() {
                var locatorButton = document.getElementById("location-button");
                locatorButton.addEventListener("click", locatorButtonPressed)
            }
            function locatorButtonPressed() {
                locatorSection.classList.add("loading")

                navigator.geolocation.getCurrentPosition(function (position) {
                    getUserAddressBy(position.coords.latitude, position.coords.longitude)
                },
                    function (error) {
                        locatorSection.classList.remove("loading")
                        alert("The Locator was denied :( Please add your address manually")
                    })
            }

            function getUserAddressBy(lat, long) {
                // swal('in ajax function')
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        var address = JSON.parse(this.responseText)
                        document.getElementById('autocomplete').value = address.results[0].formatted_address;
                        filladdress(address.results[0]);

                    }
                };
                xhttp.open("GET", "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + long + "&key=AIzaSyBkYkD1OeulFTr2F-qEFCHcX8-KtuBtwU8", true);
                xhttp.send();

            }

            var input = document.getElementById("autocomplete");
            var autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.setComponentRestrictions(
                { 'country': ['PK'] });
            autocomplete.addListener('place_changed', function () {

                var place = autocomplete.getPlace();
                filladdress(place);


            });

            init();

            function filladdress(place) {
                document.getElementById('autocomplete').value = place.formatted_address;
                for (var component in componentForm) {
                    document.getElementById(component).value = '';
                    document.getElementById(component).disabled = false;
                }

                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        var val = place.address_components[i][componentForm[addressType]];
                        document.getElementById(addressType).value = val;
                    }
                }
                locatorSection.classList.remove("loading")
            }

        })();
    }
    getLogin(event) {
        event.preventDefault();
        let shipping_address = $('#autocomplete').val();
        this.setState({
            shipping_address
        }, () => {
            let functions = this;
            if (this.state.password !== this.state.repassword) {
                swal('Password not matched.');
            }
            else if (this.state.phone === '' || this.state.password === '') {
                swal('Phone and Password required.');
            } else if (this.state.phone.length !== 10) {
                swal('Invalid Phone Number format. Format should be 3XXXXXXXXX');
            } else {
                this.setState({ loadingBtn: true })
                Axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    url: this.state.base_url + 'api/v2/auth/signupWithApi',
                    data: {
                        name: this.state.name,
                        country_codee: this.state.country_codee,
                        phone: this.state.phone,
                        password: this.state.password,
                        shipping_address: this.state.shipping_address
                    }
                }).then(function (response) {
                    functions.setState({ loadingBtn: false })
                    if (response.data.user_data) {
                        if (response.data.user_data.code === "ok") {
                            localStorage.setItem("token_id", response.data.opt_code);
                            localStorage.setItem("user_id", response.data.user_id);
                            localStorage.setItem("user_name", response.data.user_name);
                            localStorage.setItem("balance", response.data.user_data.balance);
                            localStorage.setItem("phone", functions.state.phone);
                            localStorage.setItem("password", functions.state.password);
                            localStorage.setItem("approved", 0);
                            functions.setState({
                                redirectToReferrer: true,
                            })
                        } else {
                            swal("Phone or password is invalid");
                        }
                    } else {
                        swal(response.data.message);
                    }



                });
            }
        });
    }
    componentWillMount() {
        if (localStorage.getItem("approved") === 0) {
            this.setState({ redirectToReferrer: true });
        }
        if (localStorage.getItem("approved") === '1') {
            this.setState({ loginToHome: true });
        }
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { loadingBtn } = this.state;
        if (this.state.redirectToReferrer) {
            return (<Redirect to='otpno' />)
        }
        if (this.state.loginToHome) {
            return (<Redirect to='/home' />)
        }
        return (
            <div className="yellow_bg" id="register_page">

                <span className="loader">
                    <div className="logobox">
                        <img src={logo} alt="" />
                        <img className="logo_icon" src={logo_icon} alt="" />
                        <p>Powered by Order Express</p>
                    </div>
                </span>


                <div className="login_box" id="register_shop">
                    <div className="main_head">
                        <img src={favicon_icon} alt="" />
                        <p>آپ کی اپنی دوکان </p>
                    </div>
                    <form onSubmit={this.getLogin} className="middle_form profilebox" >
                        <div className="form_box">
                            <i className="lnr lnr-user"></i>
                            <input type="text" placeholder="Full Name" required="required" name="name" onChange={this.onChange} />
                        </div>
                        <div className="form_box number_add">
                            <i className="lnr lnr-phone"></i>
                            <span className="country_code">+92</span>
                            <input type="text" placeholder="3XXXXXXXXX" required="required" name="phone" onChange={this.onChange} />
                        </div>
                        <div className="form_box">
                            <i className="lnr lnr-lock"></i>
                            <input type="password" placeholder="Password" required="required" name="password" onChange={this.onChange} />
                        </div>
                        <div className="form_box">
                            <i className="lnr lnr-lock"></i>
                            <input type="password" placeholder="Confirm Password" required="required" name="repassword" onChange={this.onChange} />
                        </div>

                        <div style={{ 'width': '100%' }} className="ui icon big input dot_line_address" id="location-input-section">
                            <input type="hidden" id="latitude" />
                            <input type="hidden" id="longitude" />
                            {/* <input type="text" placeholder="Shipping Adress" id="autocomplete" required="required" name="shipping_address" style={{ 'position': 'static' }} onChange={this.onChange} /> */}
                            <div className="refreshicon" style={{ 'width': '100%' }}>
                                <textarea style={{ 'width': '100%' }} name="shipping_address" cols="7" id="autocomplete" className="form-control" required="required" placeholder="Shipping Address" onChange={this.onChange}></textarea>
                                <i aria-hidden="true" onClick={this.getLocation} className=" dot circle outline link icon" id="location-button"></i>
                            </div>
                        </div>

                        <div className="login_btn">

                            {/* <button type="submit">
                                لاگ ان
                            </button> */}
                            <button
                                type="submit"
                                disabled={loadingBtn}
                            >
                                {loadingBtn && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loadingBtn && <span>لوڈ ہو رہا ہے
                                </span>}
                                {!loadingBtn && <span>رجسٹر</span>}
                            </button>
                            {/* </Link> */}

                            <Link to="/">لاگ ان کریں </Link>
                        </div>
                    </form>
                </div>

            </div>
        )
    }
}
export default Register;