import React, { Component } from 'react';
import { ProductConsumer } from "../context";
import Header from '../component/Header';
import { api_url } from '../Config';
import Axios from 'axios';
import $ from 'jquery';
import loader from '../assets/images/qty_boxes_03.png';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom';
class Checkout extends Component {
    constructor() {
        super();
        this.state = {
            redirectToReferer: false,
            redirectToOrders: false,
            customerDetail: [],
            base_url: api_url.base_url,
            customerAddress: [],
            deliveryInfo: [],
            time: [],
            days: [],
            btnLoading: false,
            loadingAddress: false,
            loadingInfo: false,
            // cart Paramater
            shipping_address: 'abc',
            payment_type: 'cash_on_delivery',
            payment_status: 'unpaid',
            grand_total: localStorage.getItem('grand_total'),
            shipping_charges: localStorage.getItem('shipping_cost') ? localStorage.getItem('shipping_cost') : 0,
            time_slot: '',
            cart: JSON.parse(localStorage.getItem('cart')),
            // Errors
            shipping_address_error: '',
            time_slot_error: '',
            visiable: true,
            redirectToCart: false,
        }
        this.onChange = this.onChange.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
        this.addNewAddress = this.addNewAddress.bind(this);
    }
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        this.setState({ loadingAddress: true });
        this.setState({ loadingInfo: true });
        Axios.get(this.state.base_url + 'api/v2/user/shipping/address/' + user_id)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        customerAddress: response.data.data,
                        loadingAddress: false,
                        // shipping_address: response.data.data[0].address
                    });
                    if (response.data.data.length > 0) {
                        this.setState({
                            shipping_address: response.data.data[0].address
                        });
                    }
                }
            });
        Axios.get(this.state.base_url + 'api/v2/delivery-info')
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        time: response.data[0],
                        days: response.data[1],
                        loadingInfo: false,
                        time_slot: response.data[1][0].day + ' (' + response.data[0].time + ')'
                    });
                }
            });
    }
    componentDidUpdate() {
        // $(".slots_items_box .choose_slots").click(function () {
        //     $(".set_estimated,.set_overlay").fadeIn();
        // });
        // $(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function () {
        //     $(".set_estimated,.set_overlay").fadeOut();
        // });
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    placeOrder() {
        let functions = this;
        const user_id = localStorage.getItem('user_id');
        const shipping_cost = this.state.shipping_charges;
        let grand_total = parseFloat(this.state.grand_total) + parseFloat(this.state.shipping_charges);
        const { cart, payment_type, shipping_address, payment_status, time_slot } = this.state;
        if (cart.length === 0) {
            swal('Cart is empty!');
        }
        else if (time_slot === '') {
            swal('Please select Time Slot!');
        }
        else {

            this.setState({ btnLoading: true });
            Axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.state.base_url + 'api/v2/check_qty',
                data: {
                    items: cart

                }
            }).then(function (response) {
                if (response.data.code === '401') {
                    sessionStorage.setItem("error_cart", JSON.stringify(response.data.products));
                    sessionStorage.setItem("cart_msg", 'Product Quantity Exceeded!');
                    functions.setState({ redirectToCart: true });
                }
                if (response.data.code === '200') {
                    // console.log('yes')
                    Axios({
                        method: 'post',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        url: functions.state.base_url + 'api/v2/order/store',
                        data: {
                            items: cart,
                            payment_type,
                            payment_status,
                            shipping_address,
                            shipping_cost,
                            grand_total,
                            time_slot,
                            user_id

                        }
                    }).then(function (response) {
                        sessionStorage.setItem('successfull', 'OrderExpress order krny ka shukriya!');
                        let updatedCart = [];
                        localStorage.setItem('cart', JSON.stringify(updatedCart));
                        localStorage.setItem('grand_total', 0);
                        functions.setState({ btnLoading: false, redirectToOrders: true })

                    });
                }

            });

        }
        this.setState({ btnLoading: false });

    }
    addNewAddress() {

    }

    render() {
        if (this.state.redirectToOrders) {
            return (<Redirect to="/home" />)
        }
        if (this.state.redirectToCart) {
            return (<Redirect to="/cart" />)
        }
        return (
            <ProductConsumer>
                {value => {
                    return (
                        <React.Fragment>
                            <div className="yellow_bg border_none " id="checkout_layout">
                                <Header title="Checkout" link="/cart" />

                                <section className="login_box__view listing_view purple_bg" id="height_box" style={{ height: '100vh' }}>
                                    <div className="checkout_textarea order_details searchbar myroute_search" id="wallet_page">
                                        <div className="info_order">
                                            <h4>Your Delivery Address</h4>
                                            <table className="table" id="delivery_address">
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <div className="gendar">

                                                                {
                                                                    this.state.loadingAddress ?
                                                                        <div className="loading_img checkout_loaders">
                                                                            <img src={loader} alt="loader" />
                                                                        </div>
                                                                        :

                                                                        this.state.customerAddress.length > 0 && this.state.customerAddress.map((data, id) => {
                                                                            return (
                                                                                <p key={id}>   <input checked={data.address === this.state.shipping_address} type="radio" id={`address${id}`} name="shipping_address" />
                                                                                    <label onClick={(e) => {
                                                                                        this.setState({
                                                                                            shipping_address: data.address
                                                                                        })
                                                                                    }} htmlFor={`address${id}`}> {data.address}</label> </p>
                                                                            )
                                                                        })
                                                                }
                                                            </div>
                                                        </th>
                                                        <td className="order_total fee_green"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2">
                                                            <Link to="add-new-address" > Add New Address</Link>
                                                            {/* <button onClick={this.addNewAddress}>Use Current Location</button> */}
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td colSpan="2">

                                                            
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <td colSpan="2"><input type="text" onChange={this.onChange} placeholder="Instructions for delivery team." name="instructions" className="" value="" /></td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="info_order slots_items_box" id="time_slots">
                                            <h4>Choose delivery slot</h4>
                                            <table className="table">
                                                <tbody>
                                                    {
                                                        this.state.loadingInfo ?
                                                            <div className="loading_img checkout_loaders">
                                                                <img src={loader} alt="loader" />
                                                            </div>
                                                            :
                                                            this.state.days.map((data, dayid) => {
                                                                return (

                                                                    <tr key={dayid} >
                                                                        <th id="week_slots">
                                                                            <div className="gendar">
                                                                                <input checked={this.state.time_slot === data.day + ' (' + this.state.time.time + ')'} type="radio" id={`day${dayid}`} name="time_slot" value="" />
                                                                                <label onClick={(e) => {
                                                                                    this.setState({
                                                                                        time_slot: data.day + ' (' + this.state.time.time + ')'
                                                                                    })
                                                                                }} htmlFor={`day${dayid}`}>
                                                                                    {data.day}
                                                                                </label>

                                                                            </div>

                                                                        </th>
                                                                        <td className="order_total fee_green choose_slots">{this.state.time.time}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                    {/* <tr>
                                                        <th>TOMORROW</th>
                                                        <td className="order_total fee_green choose_slots">6 MAR <img src={dots} alt="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <th>SUN </th>
                                                        <td className="order_total fee_green choose_slots">7 MAR <img src={dots} alt="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <th>MON</th>
                                                        <td className="order_total fee_green choose_slots">8 MAR <img src={dots} alt="" /></td>
                                                    </tr>
                                                    <tr>
                                                        <th>TUE</th>
                                                        <td className="order_total fee_green choose_slots">9 MAR <img src={dots} alt="" /></td>
                                                    </tr> */}

                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="info_order">
                                            <h4>Current Payment Method</h4>
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <div className="gendar">
                                                                <input type="radio" id="paymentMethod" name="radio-group" readOnly={true} checked={this.state.payment_type === 'cash_on_delivery'} />
                                                                <label htmlFor="paymentMethod">(COD) <svg id="cod" viewBox="0 0 24 24"><path d="M11 4h1v2.01c3.29.141 4 1.685 4 2.99h-1c0-1.327-1.178-2-3.5-2c-.82 0-3.5.163-3.5 2.249c0 .872 0 1.86 3.621 2.766l1.606.485C15.76 13.435 16 14.572 16 15.751c0 1.881-1.518 3.093-4 3.235V21h-1v-2.01c-3.29-.141-4-1.685-4-2.99h1c0 1.327 1.178 2 3.5 2c.82 0 3.5-.163 3.5-2.249c0-.872 0-1.86-3.621-2.766L9.773 12.5C7.24 11.565 7 10.428 7 9.249c0-1.881 1.518-3.093 4-3.235V4z" fill="#626262"></path></svg></label>
                                                            </div>
                                                        </th>
                                                        <td className="check_mark"><svg viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#51aa1a"></path></svg></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </section>


                                <div className="procced_checkout">
                                    {
                                        this.state.btnLoading ?
                                            "Loading..."
                                            :
                                            <span onClick={() => {
                                                this.placeOrder();
                                                // value.clearCart();
                                            }}>
                                                <div className="row">
                                                    <div className="col-sm-6 checkout_info">
                                                        <h4>Place Order</h4>
                                                    </div>
                                                    <div className="col-sm-6 checkout_info checkout_value">
                                                        <h6>Rs. {parseFloat(value.cartTotal) + parseFloat(this.state.shipping_charges)}</h6>
                                                    </div>
                                                </div>
                                            </span>
                                    }

                                </div>


                                {/* <div className="set_overlay"></div>
                                <div className="set_estimated">
                                    <div className="time_picker">
                                        <i className="lnr lnr-cross"></i>
                                        <b>Choose delivery slot</b>
                                        <div className="gendar">
                                            <input type="radio" id="test1" name="radio-group" checked="" value="" />
                                            <label htmlFor="test1">7:00 AM - 12:30 AM</label>
                                            <input type="radio" id="test2" name="radio-group" value="" />
                                            <label htmlFor="test2">8:00 AM - 12:30 AM</label>
                                            <input type="radio" id="test3" name="radio-group" value="" />
                                            <label htmlFor="test3">9:00 AM - 12:30 AM</label>
                                            <input type="radio" id="test4" name="radio-group" value="" />
                                            <label htmlFor="test4">7:00 AM - 12:30 AM</label>
                                        </div>
                                    </div>
                                </div> */}

                            </div>


                        </React.Fragment>
                    )
                }
                }
            </ProductConsumer >
        )
    }
}
export default Checkout;