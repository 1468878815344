import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import Footer from '../component/Footer';
import Header from '../component/Header';
import { api_url } from '../Config';
import Axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
// import loader from '../assets/images/qty_boxes_03.png';
class AddNewAddress extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            redirectToReferrer: false,
            address: '',
            loadingBtn: false
        };
        this.onChange = this.onChange.bind(this);
        this.useCurrentLocation = this.useCurrentLocation.bind(this);
        this.addAddressHandeler = this.addAddressHandeler.bind(this);
    }
    componentDidMount() {
        this.useCurrentLocation();
    }
    addAddressHandeler() {
        let functions = this;
        let address = $('#autocomplete').val();
        this.setState({
            address
        }, () => {
            if (this.state.address === '') {
                swal('Please enter your address.')
            } else {
                this.setState({ btnLoading: true });
                Axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    url: this.state.base_url + 'api/v2/user/shipping/create',
                    data: {
                        address: this.state.address,
                        user_id: localStorage.getItem('user_id')
                    }
                }).then(function (response) {
                    swal('New address added successfully.');
                    functions.setState({ btnLoading: false, address: '', redirectToReferrer: true })
                });
            }
        })


    }
    useCurrentLocation() {
        function latlong(lat, long) {
            // var latitue = lat;
            // var longitude = long;
            document.getElementById("latitude").setAttribute("value", lat);
            document.getElementById("longitude").setAttribute("value", long);
        }

        const google = window.google
        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
        };

        (function () {
            navigator.geolocation.getCurrentPosition(function (position) {
                getUserAddressBy(position.coords.latitude, position.coords.longitude);
                latlong(position.coords.latitude, position.coords.longitude)
            },
                function (error) {
                    console.log("The Locator was denied :(")
                })
            var locatorSection = document.getElementById("location-input-section")

            function init() {
                var locatorButton = document.getElementById("location-button");
                locatorButton.addEventListener("click", locatorButtonPressed)
            }
            function locatorButtonPressed() {
                locatorSection.classList.add("loading")

                navigator.geolocation.getCurrentPosition(function (position) {
                    getUserAddressBy(position.coords.latitude, position.coords.longitude)
                },
                    function (error) {
                        locatorSection.classList.remove("loading")
                        alert("The Locator was denied :( Please add your address manually")
                    })
            }

            function getUserAddressBy(lat, long) {

                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        var address = JSON.parse(this.responseText);
                        document.getElementById('autocomplete').value = address.results[0].formatted_address;
                        // filladdress(address.results[0]);

                    }
                };
                xhttp.open("GET", "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + long + "&key=AIzaSyBkYkD1OeulFTr2F-qEFCHcX8-KtuBtwU8", true);
                xhttp.send();

            }

            var input = document.getElementById("autocomplete");
            var autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.setComponentRestrictions(
                { 'country': ['PK'] });
            autocomplete.addListener('place_changed', function () {

                var place = autocomplete.getPlace();
                filladdress(place);


            });

            init();

            function filladdress(place) {
                for (var component in componentForm) {
                    document.getElementById(component).value = '';
                    document.getElementById(component).disabled = false;
                }

                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        var val = place.address_components[i][componentForm[addressType]];
                        document.getElementById(addressType).value = val;
                    }
                }
                console.log(place);
                locatorSection.classList.remove("loading")
            }

        })();

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { loadingBtn } = this.state;
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/checkout' />)
        }
        return (
            <React.Fragment>


                <body className="yellow_bg border_none">


                    <Header title="New Address" link="/myorder" />

                    <section className="login_box__view listing_view purple_bg" id="height_box" style={{ height: '100vh' }}>
                        <div className="order_details myroute_search purple_bg" id="add_location_box">

                            <div style={{ 'width': '100%' }} className="address_box ui icon big input order_new_address " id="location-input-section">
                                <div className="track_order">
                                    <input type="hidden" id="latitude" />
                                    <input type="hidden" id="longitude" />
                                    <div className="refreshicon" style={{ 'width': '100%' }}>
                                        <textarea style={{ 'width': '100%' }} className="form-control" onChange={this.onChange} name="address" placeholder="Enter Your Address" id="autocomplete"></textarea>
                                        <i aria-hidden="true" className=" dot circle outline link icon" id="location-button"></i>
                                    </div>
                                    <div className="address_btns">
                                        <button className="current_loation_btn" onClick={this.useCurrentLocation}>Use Current Location</button>
                                        <button className="add_address_btn"
                                            type="button"
                                            disabled={loadingBtn}
                                            onClick={this.addAddressHandeler}
                                        >
                                            {loadingBtn && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loadingBtn && <span>Loading
                                            </span>}
                                            {!loadingBtn && <span>Add Address</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </body>

            </React.Fragment>
        )
    }
}
export default AddNewAddress;