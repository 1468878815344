import React, { Component } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Modal from '../component/Modal';
import { Link, } from 'react-router-dom';
import axios from 'axios';
import loader from '../assets/images/qty_boxes_03.png';
import { api_url } from '../Config';
import { ProductConsumer } from "../context";
class ItemList extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            products: [],
            mainCategory: [],
            brands: [],
            img_url: api_url.img_url,
            default_img: 'assets/img/placeholder.jpg',
            loading: false,
        }
    }
    componentDidMount() {
        this.setState({ loading: true })
        let brand_id = this.props.match.params.brand_id;
        let category_id = this.props.match.params.category_id;
        let url = this.state.base_url + 'api/v2/productsWithbrandCat/brand/' + brand_id + '/' + category_id;
        if (category_id === 'all') {
            url = this.state.base_url + 'api/v2/products/brand/' + brand_id;
        }

        axios.get(url)
            .then((response) => {

                if (response.status === 200) {
                    this.setState({
                        products: response.data.data,
                        loading: false
                    })
                }
            });
        axios.get(this.state.base_url + 'api/v1/brands')
            .then((response) => {
                // console.log(response.data)
                this.setState({
                    brands: response.data.data
                })
            });
    }
    render() {
        return (
            <ProductConsumer>
                {value => {
                    return (
                        <React.Fragment>
                            <Modal />
                            <div className="yellow_bg border_none">

                                <Header title="Oreder Experess" tagline="دوکان" link="/home" />

                                <div className="yellow_bar" id="dark_bar"></div>
                                <div className="brand_bg" id="special_offer">
                                    <h4>Items List</h4>
                                </div>
                                <div id="myCarousel" className="carousel slide" data-ride="carousel">

                                    <div className="slider_box" id="items_listing">
                                        <div className="special_offer_box">
                                            <ul>
                                                {
                                                    this.state.loading ?
                                                        <div className="loading_img items_loader">
                                                            <img src={loader} alt="loader" />
                                                        </div>
                                                        :
                                                        this.state.products.length > 0 ? this.state.products.map((data, id) => {
                                                            return (
                                                                <li key={id}>
                                                                    <div className="items_box">
                                                                        <div className="tag_box">
                                                                            <h6>{data.unit}</h6>
                                                                        </div>
                                                                        <img src={value.img_url + data.thumbnail_image} alt="" />
                                                                        <div className="row">
                                                                            <div className="col-sm-12 brand_fulname">
                                                                                <span>{data.name} </span>
                                                                            </div>

                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-6 line_through">
                                                                                {
                                                                                    data.original_price > 0 &&
                                                                                    <h5>Rs. {data.original_price}</h5>
                                                                                }
                                                                            </div>
                                                                            <div className="col-sm-6 brand__name">
                                                                                <span className="price__box">Rs. {data.sale_price}</span>
                                                                            </div>
                                                                        </div>
                                                                        <Link
                                                                            to="#"
                                                                            onClick={() => {
                                                                                // value.addToCart(id);
                                                                                value.openModal(data);
                                                                            }}
                                                                        >
                                                                            ابھی خریدیں
                                                                        </Link>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }) :
                                                            <div className="no_records">
                                                                <p>No record Found!</p>
                                                            </div>
                                                }

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <Footer />
                        </React.Fragment>
                    )
                }}
            </ProductConsumer>
        )
    }
}
export default ItemList;
